<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarRequ") }}</h1>
        <div id="about-text">
          {{ $t("message.RequText") }}
        </div>
        <v-simple-table id = "font-st">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in requ" :key="item.name">
              <td id = "left-c">{{ $t(item.name) }}</td>
              <td>{{ $t(item.text) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>
      
    </div>
  </div>
</template>

<script>
import CompanyDrawer from "../../../views/Navigations/Company/CompanyDrawer";
export default {
  data() {
    return {
      requ: [
        {
          name: "message.FullNameTitle",
          text: "message.FullNameText",
        },
        {
          name: "message.ShortNameTitle",
          text: "message.ShortNameText",
        },
        {
          name: "message.INN",
          text: 6316150525/631501001,
        },
        {
          name: "message.ORGN",
          text: 1096316014299,
        },
        {
          name: "message.LegalAd",
          text: "message.LegalAdText",
        },
        {
          name: "message.FactAd",
          text: "message.FactAdText",
        },
        {
          name: "message.PhoneFaks",
          text: "message.PhoneFaksText",
        },
        {
          name: "message.Email",
          text: "message.EmailText",
        },
        {
          name: "message.WebSite",
          text: "message.WebSiteText",
        },
        {
          name: "message.BankRequ",
          text: "message.BankRequText",
        },
      ],
    };
  },
  components: {
    CompanyDrawer,
  },
};
</script>
<style>
#left-c {
    font-weight: 600;
}
#font-st{
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
</style>